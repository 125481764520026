<template>
    <section class="productos-desconocidos">
        <div v-show="cedisNavbar.length == 1" class="row mx-0 bg-white br-t-8 align-items-center my-2 py-3">
            <div class="col-auto text-general">
                <b> {{ total_productos }} </b>  Producto desconocidos en <b> {{ total_pedidos }} </b>  Pedidos en gestión
            </div>
            <div class="col-xl-4 col-lg-5 col-md-5 col-sm-5 col-5 ml-auto">
                <el-input v-model="buscar" class="br-20" size="small" clearable placeholder="Buscar producto desconocido" />
            </div>
        </div>
        <div v-if="cedisNavbar.length == 1" class="row mx-0">
            <div class="col-12 px-0">
                <el-table
                :data="productos_desconocidos.filter(data => !buscar || data.desconocido.toLowerCase().includes(buscar.toLowerCase()))"
                header-row-class-name="text-general"
                stripe
                class="cr-pointer"
                style="width:100%;"
                @row-click="seleccionar_pedido"
                >
                    <el-table-column label="Producto desconocido" min-width="250">
                        <template slot-scope="{row}">
                            <div class="row mx-0">
                                <div v-show="pedido_producto == row.id_pedido_producto" class="bg-general br-10" style="height:60px;width:8px;" />
                                <div class="col d-middle">
                                    <p class="text-general f-500 nombre">
                                        {{ row.desconocido }}
                                    </p>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="id" label="Pedido" align="center" min-width="80" />
                    <el-table-column label="Estado" align="center" min-width="120">
                        <template slot-scope="{row}">
                            <p> {{ fun_estado(row.estado) }}</p><p />
                        </template>
                    </el-table-column>
                    <el-table-column prop="tendero" :label="`${$config.vendedor}`" min-width="200" />
                    <el-table-column label="Entrega" align="center" min-width="130">
                        <template slot-scope="{row}">
                            <p> {{ row.entrega_fecha | helper-fecha('DD MMM') }}</p>
                            <p> {{ row.entrega_horario + ':00 Hrs' }}</p>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div v-else class="row mx-0 py-2 justify-center text-general-red bg-whitesmoke2 br-10 my-3">
            Por favor seleccione un centro de distribución para realiazar la busqueda.
        </div>
        <modalProductoDesconocido ref="modalProductoDesconocido" @update="listar_desconocidos" />
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import Pedidos from '~/services/pedidos/pedidos-admin'
export default {
    components:{
        modalProductoDesconocido: () => import('../pedidos/partials/modalProductoDesconocido'),
    },
    data(){
        return {
            buscar: '',
            productos_desconocidos:[],
            pedido_producto:-1
        }
    },
    computed:{
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            cedisNavbar:'cedis/cedisNavbar',
            id_pedido: 'pedidos/pedidos_admin/id_pedido',
            productos: 'pedidos/pedidos_admin/productos',
        }),
        total_pedidos(){
            return _.uniqBy(this.productos_desconocidos,'id').length
        },
        total_productos(){
            return this.productos_desconocidos.length
        }
    },
    watch:{
        id_cedis(val){
            this.listar_desconocidos()

            this.$store.commit('pedidos/pedidos_admin/set_id_pedido', null)
            this.$store.commit('pedidos/pedidos_admin/set_pedido', {})
            this.$store.commit('pedidos/pedidos_admin/set_productos', [])
            this.pedido_producto = -1
            this.productos_desconocidos = []
            this.buscar = ''
        }

    },
    mounted(){
        this.listar_desconocidos()
    },
    methods:{
        async listar_desconocidos(){
            try {
                if(this.cedisNavbar.length != 1) return
                const {data} = await Pedidos.listar_desconocidos(this.cedisNavbar[0].id)
                this.productos_desconocidos = data.productos

            } catch (e){
                this.error_catch(e)
            }
        },
        seleccionar_pedido(row){
            this.pedido_producto= row.id_pedido_producto
            this.$store.commit('pedidos/pedidos_admin/set_id_pedido', row.id)
            if(!this.$can('botones_abastecimiento_gestionar_productos')) return
            let that = this
            setTimeout(function(){
                let producto = that.productos.find(o=>o.id === row.id_pedido_producto)
                that.$refs.modalProductoDesconocido.toggle(producto)
            }, 800);

        },
        fun_estado(state){
            switch (state){
            case 1:
                return 'Sin Confirmar'
            case 2:
                return 'Confirmado'
            case 201:
                return 'Alistado'
            case 202:
                return 'Empacado'
            case 3:
                return 'Enviado'
            case 4:
                return 'Entregado'
            case 25:
                return 'Rechazado'
            case 26:
				const temp = `Cancelado ${this.$config.vendedor}`
                return temp;
            case 27:
                return 'Cancelado Admin'
            default:

            }
        }
    }
}
</script>
<style lang="scss" scoped>
.br-12{
    border-radius: 12px !important;
}
</style>
